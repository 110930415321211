@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 100%;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    padding: 20px 5px;
}

.home #logo {
    width: 100vw;
    max-width: 400px;
    min-width: 300px;
}

.home .card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 650px;
}

.home .teste-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 25px 10px;
    margin: 20px 0;
    width: 100%;
    max-width: 300px;
}

.home .titulo-site {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home .titulo-site h1 {
    font-size: 2.65em;
    font-weight: 900;
    margin-bottom: 2vh;
    line-height: 1.0em;
}

.home .titulo-site h2 {
    font-size: 2.15em;
    font-weight: 700;
    margin-top: 3vh;
}

.home .teste-container h2 {
    margin-top: 10px;
    font-size: 1.8em;
    font-weight: 700;
}

.home .teste-container p {
    font-size: 1.4em;
    color: #444;
}

.home svg {
    height: 100px;
    width: 100px;
}

.home .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    margin-top: 25px;
    text-decoration: none;
}

.home a {
    text-decoration: none;
    width: 100%;
}

.home button {
    height: 100%;
}

.home .button:hover {
    background: rgb(30, 141, 76);
}

@media screen and (min-width: 610px) {
    .home .card-container {
        flex-direction: row;
    }
}

@media screen and (min-height: 751px) and (min-width: 751px) { /* iPad Pro */
    .home {
        font-size: 22px;
        max-height: 750px;
    }
    
    .home a {
        height: 80px;
    }

    .home #logo, .home a {
        max-width: 600px;
    }

    .home svg {
        width: 120px;
        height: 120px;
    }

    .home .teste-container {
        max-width: 350px;
    }

    .home .card-container {
        max-width: 750px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .home {
        font-size: 28px;
        max-height: 1100px;
    }
}