.perguntas .escala path[data-id="6"] {
    stroke: #07AD8F;
}

.perguntas .escala path[data-id="5"] {
    stroke: #34AE98;
}

.perguntas .escala path[data-id="4"] {
    stroke: #4DAB9A;
}

.perguntas .escala path[data-id="3"] {
    stroke: #5D9F93;
}

.perguntas .escala path[data-id="2"] {
    stroke: #5A9288;
}

.perguntas .escala path[data-id="1"] {
    stroke: #678C85;
}

.perguntas .escala path[data-id="0"] {
    stroke: #6C7C7B;
}

.perguntas .escala path[data-id="6"], .perguntas .escala-numero path[data-id="6"] {
    fill: #07AD8F;
}

.perguntas .escala path[data-id="5"], .perguntas .escala-numero path[data-id="5"] {
    fill: #34AE98;
}

.perguntas .escala path[data-id="4"], .perguntas .escala-numero path[data-id="4"] {
    fill: #4DAB9A;
}

.perguntas .escala path[data-id="3"], .perguntas .escala-numero path[data-id="3"] {
    fill: #5D9F93;
}

.perguntas .escala path[data-id="2"], .perguntas .escala-numero path[data-id="2"] {
    fill: #5A9288;
}

.perguntas .escala path[data-id="1"], .perguntas .escala-numero path[data-id="1"] {
    fill: #678C85;
}

.perguntas .escala path[data-id="0"], .perguntas .escala-numero path[data-id="0"] {
    fill: #6C7C7B;
}

.perguntas .escala path[data-checked="false"] {
    fill: transparent;
}

.perguntas .escala-numero path[data-checked="true"] {
    fill: #fff;
}