@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 100%;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
            user-select: none;
    padding: 20px 5px;
}

.home #logo {
    width: 100vw;
    max-width: 400px;
    min-width: 300px;
}

.home .card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 650px;
}

.home .teste-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 25px 10px;
    margin: 20px 0;
    width: 100%;
    max-width: 300px;
}

.home .titulo-site {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home .titulo-site h1 {
    font-size: 2.65em;
    font-weight: 900;
    margin-bottom: 2vh;
    line-height: 1.0em;
}

.home .titulo-site h2 {
    font-size: 2.15em;
    font-weight: 700;
    margin-top: 3vh;
}

.home .teste-container h2 {
    margin-top: 10px;
    font-size: 1.8em;
    font-weight: 700;
}

.home .teste-container p {
    font-size: 1.4em;
    color: #444;
}

.home svg {
    height: 100px;
    width: 100px;
}

.home .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    margin-top: 25px;
    text-decoration: none;
}

.home a {
    text-decoration: none;
    width: 100%;
}

.home button {
    height: 100%;
}

.home .button:hover {
    background: rgb(30, 141, 76);
}

@media screen and (min-width: 610px) {
    .home .card-container {
        flex-direction: row;
    }
}

@media screen and (min-height: 751px) and (min-width: 751px) { /* iPad Pro */
    .home {
        font-size: 22px;
        max-height: 750px;
    }
    
    .home a {
        height: 80px;
    }

    .home #logo, .home a {
        max-width: 600px;
    }

    .home svg {
        width: 120px;
        height: 120px;
    }

    .home .teste-container {
        max-width: 350px;
    }

    .home .card-container {
        max-width: 750px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .home {
        font-size: 28px;
        max-height: 1100px;
    }
}
.apresentacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 100%;
    max-height: 750px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
            user-select: none;
}

.apresentacao .conteudo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.apresentacao img, .apresentacao .button {
    width: 90vw;
    max-width: 400px;
}

.apresentacao img {
    margin-top: 30px;
}

.apresentacao svg {
    transform: scale(1.4, 1.4);
    margin: 40px 0;
}

.apresentacao a {
    text-decoration: none;
}

.apresentacao h1 {
    font-size: 2.65em;
    font-weight: 900;
    margin-bottom: 2vh;
}

.apresentacao h2 {
    font-size: 2.15em;
    font-weight: 700;
    margin-top: 3vh;
}

.apresentacao p {
    font-size: 1.5em;
    text-align: center;
    max-width: 90vw;
}

.apresentacao strong {
    color: #000;
}

.apresentacao h1, .apresentacao h2 {
    line-height: 1.0em;
    text-align: center;
}

.apresentacao .subtitulo {
    color: #666;
}

.apresentacao .button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    margin: 15px 0 50px;
}

.apresentacao .button:hover {
    background: rgb(30, 141, 76);
}

@media screen and (max-height: 600px) {
    .apresentacao svg {
        transform: scale(1.2, 1.2);
        margin: 30px 0;
    }

    .apresentacao .button {
        margin-bottom: 20px;
    }
}

@media screen and (min-height: 751px) and (min-width: 751px) { /* iPad Pro */
    .apresentacao {
        font-size: 22px;
    }
    
    .apresentacao .button {
        height: 80px;
    }

    .apresentacao img, .apresentacao .button {
        max-width: 600px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .apresentacao {
        font-size: 28px;
        max-height: 1100px;
    }

    .apresentacao svg {
        transform: scale(1.6, 1.6);
        margin: 40px 0;
    }
}
.instrucoes {
  display: grid;
  margin: 0 auto;
  grid-template-rows: auto auto 55px;
  grid-row-gap: 20px;
  row-gap: 20px;
  max-width: 800px;
  min-height: 100%;
  max-height: 750px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  -webkit-user-select: none;
          user-select: none;
  padding: 30px 20px 50px;
}

.instrucoes .conteudo {
  height: 100%;
  line-height: 1.5em;
}

.instrucoes img {
  width: 30px;
  opacity: 0.5;
  position: fixed;
  top: 10px;
  left: 5vw;
  display: none;
}

.instrucoes h1 {
  font-size: 2.15em;
  font-weight: 700;
  text-align: center;
}

.instrucoes p {
  font-size: 1.1em;
  margin-top: 20px;
  max-width: 90vw;
  text-align: justify;
}

.instrucoes > * {
  text-decoration: none;
}

.instrucoes .button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #27ae60;
  border-radius: 3px;
  font-size: 1.5em;
  height: 55px;
  margin: 0 auto;
}

.instrucoes .button:hover {
  background: rgb(30, 141, 76);
}

.instrucoes .tudo-a-ver {
  color: #08b696;
  font-weight: 700;
}

.instrucoes .nada-a-ver {
  color: #6c7c7b;
  font-weight: 700;
}

.instrucoes .numero {
  color: #666;
}

.instrucoes .politica {
  text-decoration: underline;
  color: #1075ad;
}

.instrucoes .author {
  font-style: italic;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.instrucoes .author p {
  margin-bottom: 1rem;
}

.instrucoes .author p[data-show="false"] {
  display: none;
}

@media screen and (max-height: 600px) {
  /* small cell phones */
  .instrucoes {
    padding: 20px 10px;
  }
  .instrucoes h1 {
    font-size: 1.5em;
  }

  .instrucoes p {
    margin-top: 10px;
  }
}

@media screen and (min-height: 751px) and (min-width: 751px) {
  /* iPad Pro */
  .instrucoes {
    font-size: 22px;
    grid-template-rows: auto 80px;
  }

  .instrucoes .button {
    height: 80px;
  }

  .instrucoes img {
    top: calc(50vh - 500px);
  }

  .instrucoes p {
    margin-top: 30px;
  }
}

@media screen and (min-height: 1000px) and (min-width: 751px) {
  /* iPad */
  .instrucoes {
    font-size: 28px;
    max-height: 1100px;
  }
}

@media screen and (min-width: 800px) {
  .instrucoes img {
    left: calc(50vw - 400px);
  }
}

.perguntas {
    display: grid;
    grid-template-rows: 25px auto 55px;
    grid-row-gap: 20px;
    row-gap: 20px;
    margin: 0 auto;
    max-width: 800px;
    min-height: 100%;
    max-height: 750px;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    -webkit-user-select: none;
            user-select: none;
    padding: 30px 20px 50px;
}

.perguntas .progresso {
    text-align: left;
    max-width: 800px;
}

.perguntas .progresso-fundo {
    display: inline-block;
    background: linear-gradient(to bottom, #bdbbbb, #e7e7e7);
    width: calc(100% - 6ch);
    max-width: calc(800px - 6ch);
    height: 8px;
    vertical-align: middle;
}

.perguntas .progresso-barra {
    background: linear-gradient(to top, rgb(32, 168, 89), rgb(53, 221, 123));
    width: 0%;
    height: 100%;
    vertical-align: middle;
}

.perguntas .progresso-percentual {
    display: inline-block;
    color: #777;
    font-size: 1.1em;
    margin-left: 1ch;
}

.perguntas .conteudo {
    height: 100%;
    max-width: 800px;
    text-align: center;
}

.perguntas .questao-numero {
    color: #777;
}

.perguntas .questao {
    text-align: justify;
    font-size: 1.1em;
    line-height: 1.5em;
}

.perguntas .pergunta-disc {
    display: inline-block;
    margin-left: 1.7ch;
    font-weight: 700;
}

.perguntas .escala {
    display: block;
    margin: 20px auto 0;
    stroke-width: 2px;
    cursor: pointer;
}

.perguntas .escala-numero {
    display: block;
    margin: -47px auto 0;
    cursor: pointer;
}

.perguntas .button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    cursor: pointer;
    margin: 0 auto;
    -webkit-user-select: none;
            user-select: none;
    
    pointer-events: none;
    opacity: 0.6;
}

.perguntas .button[data-status="enabled"] {
    opacity: 1.0;
    pointer-events: all;
}

.perguntas .button:hover {
    background: rgb(30, 141, 76);
}

.perguntas img {
    width: 30px;
    opacity: 0.5;
    position: fixed;
    top: 10px;
    left: 5vw;

    display: none;
}

.perguntas .tudo-a-ver {
    color: #08B696;
    font-weight: 700;
    float: right;
}

.perguntas .nada-a-ver {
    color: #6C7C7B;
    font-weight: 700;
    float: left;
}

.perguntas .a-ver {
    width: 100%;
    max-width: 250px;
    margin: 5px auto 0;
    height: 10px;
    font-size: 1.2em;
}

@media screen and (min-width: calc(510px + 10vw)) {
    .perguntas .a-ver {
        max-width: calc(250px + 20ch);
        margin: -1.5em auto 0;
    }
}

@media screen and (max-height: 600px) {
    .perguntas {
        font-size: 16px;
        padding: 20px 10px;
    }
    
    .perguntas p {
        margin-top: 10px;
    }

    .perguntas .button {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 500px) {
    .perguntas .a-ver {
        margin-top: calc(-1em - 10px);
        max-width: calc(250px + 18ch);
    }
}

@media screen and (min-height: 751px) and (min-width: 751px) { /* iPad Pro */
    .perguntas {
        font-size: 22px;
        grid-template-rows: 35px auto 80px;
    }
    
    .perguntas .button {
        height: 80px;
    }

    .perguntas img {
        top: calc(50vh - 500px);
    }

    .perguntas .a-ver {
        margin-top: calc(-1.2em);
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .perguntas {
        font-size: 28px;
        max-height: 1100px;
    }
}

@media screen and (min-width: 800px) {
    .perguntas img {
        left: calc(50vw - 400px);
    }
}
.perguntas .escala path[data-id="6"] {
    stroke: #07AD8F;
}

.perguntas .escala path[data-id="5"] {
    stroke: #34AE98;
}

.perguntas .escala path[data-id="4"] {
    stroke: #4DAB9A;
}

.perguntas .escala path[data-id="3"] {
    stroke: #5D9F93;
}

.perguntas .escala path[data-id="2"] {
    stroke: #5A9288;
}

.perguntas .escala path[data-id="1"] {
    stroke: #678C85;
}

.perguntas .escala path[data-id="0"] {
    stroke: #6C7C7B;
}

.perguntas .escala path[data-id="6"], .perguntas .escala-numero path[data-id="6"] {
    fill: #07AD8F;
}

.perguntas .escala path[data-id="5"], .perguntas .escala-numero path[data-id="5"] {
    fill: #34AE98;
}

.perguntas .escala path[data-id="4"], .perguntas .escala-numero path[data-id="4"] {
    fill: #4DAB9A;
}

.perguntas .escala path[data-id="3"], .perguntas .escala-numero path[data-id="3"] {
    fill: #5D9F93;
}

.perguntas .escala path[data-id="2"], .perguntas .escala-numero path[data-id="2"] {
    fill: #5A9288;
}

.perguntas .escala path[data-id="1"], .perguntas .escala-numero path[data-id="1"] {
    fill: #678C85;
}

.perguntas .escala path[data-id="0"], .perguntas .escala-numero path[data-id="0"] {
    fill: #6C7C7B;
}

.perguntas .escala path[data-checked="false"] {
    fill: transparent;
}

.perguntas .escala-numero path[data-checked="true"] {
    fill: #fff;
}
.avaliacao {
    display: grid;
    grid-template-rows: auto auto 55px;
    grid-row-gap: 20px;
    row-gap: 20px;
    margin: 0 auto;
    max-width: 800px;
    min-height: 100%;
    max-height: 750px;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    -webkit-user-select: none;
            user-select: none;
    padding: 30px 20px 50px;
}

.avaliacao h1 {
    font-size: 1.9em;
    text-align: center;
    font-weight: 700;
}

.avaliacao p {
    font-size: 1.3em;
    text-align: center;
    color: #787878;
    margin: 10px auto 0;
    width: 90vw;
    max-width: 400px;
}

.avaliacao .form {
    height: 100%;
    width: 90vw;
    max-width: 400px;
    margin: 0 auto;
}

.avaliacao .fields {
    height: 2.33em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
}

.avaliacao .fields input {
    font-size: 1em;
    height: 1.8em;
    border: 1px solid #000;
    width: calc(100% - 45px);
    padding: 0 10px;
    border-radius: 2px;
}

.avaliacao .fields input#city {
    width: calc(100% - 45px - 5.5ch - 14px);
}

.avaliacao .fields select {
    font-size: 1em;
    width: 5.5ch;
    height: 1.8em;
    border-radius: 2px;
    border: 1px solid black;
}

.avaliacao .fields input::placeholder {
    color: #BDBDBD;
}

.avaliacao [data-status="error"] .fields input, .avaliacao [data-status="error"] .fields select {
    border-color: #E50D0D;
}

.avaliacao [data-status="valid"] .fields input, .avaliacao [data-status="valid"] .fields select {
    border-color: #219653;
}

.avaliacao .fields svg {
    display: inline-block;
    stroke-width: 2px;
}

.avaliacao [data-status="error"] .fields svg {
    fill: #E50D0D;
}

.avaliacao [data-status="valid"] .fields svg {
    fill: #219653;
}

.avaliacao #name-icon {
    width: 46px;
    height: 42px;
}

.avaliacao #whatsapp-icon {
    width: 30px;
    height: 30px;
    margin-right: 6px;
}

.avaliacao #home-icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.avaliacao #at-symbol {
    font-size: 30px;
    font-weight: 700;
    margin-right: 10px;
    padding-bottom: 0.2em;
}

.avaliacao [data-status="error"] #at-symbol {
    color: #E50D0D;
}

.avaliacao [data-status="valid"] #at-symbol {
    color: #219653;
}

.avaliacao .button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    margin: 0 auto;
    width: 90vw;
    max-width: 400px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    border: none;
    text-decoration: none;
}

.avaliacao .button[data-enabled='false'] {
    pointer-events: none;
    opacity: 0.6;
}

.avaliacao .button:hover, .avaliacao .button:focus {
    background: rgb(30, 141, 76);
}

.avaliacao .politica {
    display: inline-block;
    color: #1075AD;
    text-decoration: none;
    border-bottom: 2px solid rgba(16, 117, 173, 0.5);
    padding-bottom: 1px;
}

.avaliacao .politica:focus {
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.avaliacao .mensagem-erro {
    font-size: 0.8em;
    color: #E50D0D;
    width: calc(100% - 45px);
    text-align: left;
    margin: 0;
    height: 2.5em;
    opacity: 0;
}

.avaliacao [data-status="error"] .mensagem-erro {
    opacity: 1;
}

@media screen and (min-height: 751px) and (min-width: 751px) { /* iPad Pro */
    .avaliacao {
        grid-template-rows: auto auto 80px;
    }
    .avaliacao {
        font-size: 22px;
    }
    
    .avaliacao .button {
        height: 80px;
        max-width: 600px;
    }

    .avaliacao .form, .avaliacao .fields {
        max-width: 600px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .avaliacao {
        font-size: 28px;
        max-height: 1100px;
    }
}
.resultados-container {
    font-family: 'Lato', sans-serif;
    min-height: 100%;
    font-size: 18px;
    -webkit-user-select: none;
            user-select: none;
}

.resultados {
    display: grid;
    grid-template-rows: 5em auto;
    grid-gap: 10px;
    min-height: 100%;
    margin: 0 auto;
    max-width: 1040px;
    padding: 30px 20px 80px;
}

.resultados .cabecalho h1 {
    font-size: 2em;
    text-align: center;
    line-height: 1em;
}

.resultados .cabecalho p {
    font-size: 1.3em;
    text-align: center;
    color: #787878;
    margin-top: 10px;
    margin: 0 auto;
}

.resultados .conteudo-principal {
    max-width: 990px; /* parent-width - parent-padding - grid-gp */
    align-self: center;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto auto;
    grid-gap: 10px;
}

.resultados .resultado {
    width: 100%;
    min-width: 260px;
    position: relative;
}

.resultados .acertos {
    text-align: left;
    width: 100%;
}

.resultados .acertos-fundo {
    display: inline-block;
    background: linear-gradient(to bottom, #bdbbbb, #e7e7e7);
    width: calc(100vw - 6ch);
    max-width: calc(100% - 7ch);
    height: 8px;
}

.resultados .acertos-barra {
    background: linear-gradient(to top, rgb(32, 168, 89), rgb(53, 221, 123));
    width: 0%;
    height: 100%;
    vertical-align: middle;
}

.resultados .resultado[data-precision="low"] .acertos-barra {
    background: linear-gradient(to top, rgb(192, 71, 0), rgb(255, 95, 2));
}

.resultados .acertos-percentual {
    display: inline-block;
    color: #000;
    margin-left: 1ch;
}

.resultados iframe {
    width: 100%;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
}

.resultados .percentual {
    font-family: 'Lato';
    font-size: 60px;
    color: #219653;
}

.resultados .resultado[data-precision="low"] .percentual {
    color: rgb(235, 88, 3);
}

.resultados .tipo {
    font-family: 'Lato';
    font-size: 36px;
    color: #000;
    font-weight: 700;
    text-transform: capitalize;
}

.resultados .icon {
    height: 110px;
    position: absolute;
    top: 0;
    right: 0;
}

.resultados .descricao {
    font-size: 1.1em;
    line-height: 1.5em;
    color: #333333;
}

.resultados .descricao, .resultados .community-description {
    font-size: 1em;
    line-height: 1.5em;
    color: #333333;
}

.resultados .descricao strong, .resultados .descricao b {
    font-weight: 900;
    color: #000;
}

.resultados-container .controle-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-top: 10px;
    height: 80px;
    background: rgba(255, 255, 255, 0.75);
}

.resultados-container .controle {
    display: flex;
    width: 100%;
    max-width: 400px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4em;
    color: #828282;
    z-index: 1;
}

.resultados-container .controle .previous-button, .resultados-container .controle .next-button {
    height: 40px;
    width: 40px;
    cursor: pointer;
    transition-duration: 0.25s;
}

.resultados-container .controle img[data-status="disabled"] {
    opacity: 0.5;
    pointer-events: none;
}

.resultados-container .controle .previous-button {
    transform: rotateZ(180deg);
}

.resultados.veja-mais {
    display: flex;
    flex-direction: column;
}

.veja-mais .telegram-button {
    height: 55px;
    width: 100%;
    border-radius: 3px;
    background-color: #29A4E0;
    padding: 0.25rem;
    border: none;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #fff;
    max-width: 400px;
    margin: 15px auto 0;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
.veja-mais .telegram-button:hover {
    background-color: #1B96D2;    
}

.veja-mais .telegram-button .telegram-logo {
    height: 24px;
    margin-left: 0.5rem;
}

.veja-mais {
    grid-template-rows: 320px auto;
}

.veja-mais .cabecalho h1 {
    font-size: 2em;
    line-height: 1.2em;
}

.veja-mais .cabecalho p {
    font-size: 1.5em;
    max-width: 400px;
    margin: 10px auto 0;
}

.veja-mais .button-wrapper a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    margin: 15px auto 0;
    width: 100%;
    max-width: 600px;
    min-width: 250px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    text-decoration: none;
}

.veja-mais .button-wrapper a:hover {
    background: rgb(30, 141, 76);
}

.veja-mais .community-cards-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 32px;
    row-gap: 32px;
    grid-column-gap: 20px;
    column-gap: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 32px auto;
}

.veja-mais .community-card {
    text-decoration: none;
    width: 180px;
    height: 140px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    transition-duration: 0.25s;

    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    }
}

.veja-mais .community-card .community-type-container {
    flex:1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.veja-mais .community-card > img {
    height: 50px;
}

.veja-mais .community-card .community-type-container span {
    font-size: 20px;
    font-weight: 400;
    color: #222;
    text-transform: capitalize;
}

.veja-mais .community-card .whatsapp-container {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    color: #075E54;
}

.veja-mais .community-card .whatsapp-container span {
    font-size: 16px;
    font-weight: 400;
    color: #075E54;
}

@media screen and (max-width: 850px) {
    .resultados {
        font-size: 16px;
        grid-row-gap: 10px;
        row-gap: 10px;
        padding: 20px 20px 80px;
    }

    .resultados .conteudo-principal {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-row-gap: 10px;
        row-gap: 10px;
    }

    .resultados .resultado {
        grid-row: 1 / 2;
        width: 100%;
        height: 100%;
    }
    
    .resultados iframe {
        height: 100%;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    
    .resultado .descricao {
        grid-row: 3 / 4;
        width: 100%;
        height: 100%;
    }

    .resultados .percentual {
        font-size: 40px;
    }
    
    .resultados .tipo {
        font-size: 24px;
    }
    
    .resultados .icon {
        height: 70px;
    }
}

@media screen and (max-width: 600px) {
    .resultados {
        padding: 20px 10px 80px;
    }

    .veja-mais .community-cards-grid {
        display: flex;
        flex-direction: column;
        width: 100%;
        grid-row-gap: 12px;
        row-gap: 12px;
        margin: 16px auto;
    }

    .veja-mais .community-card {
        width: 100%;
        height: 90px;
        padding: 16px 32px;

        flex-direction: row;
    }

    .veja-mais .community-card .community-type-container {
        height: 100%;
    }

    .veja-mais .community-card .community-type-container > span {
        font-size: 24px;
    }
}

@media screen and (min-height: 751px) and (min-width: 751px) {
    .veja-mais {
        grid-template-rows: 420px auto;
    }

    .resultados {
        font-size: 22px;
        max-width: 1200px;
        padding-bottom: 100px;
    }

    .resultados .conteudo-principal {
        max-width: 1150px; /*parent-width - parent-padding - grid-gap */
    }

    .resultados-container .controle-container {
        height: 100px;
    }

    .veja-mais .button-wrapper a, .veja-mais .telegram-button {
        height: 80px;
        max-width: 600px;
    }

    .veja-mais .telegram-button .telegram-logo {
        height: 36px;
        margin-left: 1.5rem;
    }

    .veja-mais .links, .veja-mais .cabecalho p, .resultados-container .controle {
        max-width: 600px;
    }

    .resultados-container .controle {
        max-width: 600px;
    }

    .resultados-container .controle .previous-button, .resultados-container .controle .next-button {
        height: 60px;
        width: 60px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .resultados-container {
        font-size: 28px;
        max-height: 1100px;
    }

    .resultados .resultado {
        grid-row: 1 / 2;
    }

    .resultados .conteudo-principal {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-row-gap: 10px;
        row-gap: 10px;
    }
    
    .resultados iframe {
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    
    .resultado .descricao {
        grid-row: 3 / 4;
    }
}


.icones-utilizados {
    min-height: 100%;
    margin: 0 auto;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    -webkit-user-select: none;
            user-select: none;
    padding: 30px 0 50px;
}

.icones-utilizados h1 {
    font-size: 2.0em;
    text-align: center;
    margin-bottom: 20px;
}

.icones-utilizados .icons-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.icones-utilizados .icons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 110px;
    width: 315px;
    padding: 5px;
    margin: 15px auto;
}

.icones-utilizados .data-container {
    height: 100%;
    min-width: 190px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
}

.icones-utilizados .icons-container h2 {
    font-size: 1.2em;
    color: #219653;
}

.icones-utilizados .icons-container p {
    font-size: 0.9em;
}

.icones-utilizados .icons-container a {
    color: #1075AD;
}

.icones-utilizados .icons-container img {
    height: 90px;
    max-width: 90px;
}

.icones-utilizados .icons-container .free-license {
    color: #666;
    font-weight: 700;
}

.icones-utilizados #leader {
    margin-left: 15px;
}
.politica-privacidade {
    max-width: 800px;
    font-size: 16px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Lato';
}

.politica-privacidade h1 {
    text-align: center;
    margin: 0 0 20px;
}

.politica-privacidade h2 {
    font-size: 1.6em;
    margin-top: 30px;
}

.politica-privacidade h2:first-child {
    margin-top: 10px;
}

.politica-privacidade p {
    font-size: 1.1em;
    margin-top: 10px;
    text-align: justify;
    line-height: 1.5em;
}

.politica-privacidade p:last-child {
    margin-bottom: 20px;
}

.politica-privacidade a {
    text-decoration: underline;
    color: #1075AD;
}

.politica-privacidade .nome-site {
    text-decoration: none;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
