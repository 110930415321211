@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

.politica-privacidade {
    max-width: 800px;
    font-size: 16px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Lato';
}

.politica-privacidade h1 {
    text-align: center;
    margin: 0 0 20px;
}

.politica-privacidade h2 {
    font-size: 1.6em;
    margin-top: 30px;
}

.politica-privacidade h2:first-child {
    margin-top: 10px;
}

.politica-privacidade p {
    font-size: 1.1em;
    margin-top: 10px;
    text-align: justify;
    line-height: 1.5em;
}

.politica-privacidade p:last-child {
    margin-bottom: 20px;
}

.politica-privacidade a {
    text-decoration: underline;
    color: #1075AD;
}

.politica-privacidade .nome-site {
    text-decoration: none;
}