@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap");

.instrucoes {
  display: grid;
  margin: 0 auto;
  grid-template-rows: auto auto 55px;
  row-gap: 20px;
  max-width: 800px;
  min-height: 100%;
  max-height: 750px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  user-select: none;
  padding: 30px 20px 50px;
}

.instrucoes .conteudo {
  height: 100%;
  line-height: 1.5em;
}

.instrucoes img {
  width: 30px;
  opacity: 0.5;
  position: fixed;
  top: 10px;
  left: 5vw;
  display: none;
}

.instrucoes h1 {
  font-size: 2.15em;
  font-weight: 700;
  text-align: center;
}

.instrucoes p {
  font-size: 1.1em;
  margin-top: 20px;
  max-width: 90vw;
  text-align: justify;
}

.instrucoes > * {
  text-decoration: none;
}

.instrucoes .button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #27ae60;
  border-radius: 3px;
  font-size: 1.5em;
  height: 55px;
  margin: 0 auto;
}

.instrucoes .button:hover {
  background: rgb(30, 141, 76);
}

.instrucoes .tudo-a-ver {
  color: #08b696;
  font-weight: 700;
}

.instrucoes .nada-a-ver {
  color: #6c7c7b;
  font-weight: 700;
}

.instrucoes .numero {
  color: #666;
}

.instrucoes .politica {
  text-decoration: underline;
  color: #1075ad;
}

.instrucoes .author {
  font-style: italic;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.instrucoes .author p {
  margin-bottom: 1rem;
}

.instrucoes .author p[data-show="false"] {
  display: none;
}

@media screen and (max-height: 600px) {
  /* small cell phones */
  .instrucoes {
    padding: 20px 10px;
  }
  .instrucoes h1 {
    font-size: 1.5em;
  }

  .instrucoes p {
    margin-top: 10px;
  }
}

@media screen and (min-height: 751px) and (min-width: 751px) {
  /* iPad Pro */
  .instrucoes {
    font-size: 22px;
    grid-template-rows: auto 80px;
  }

  .instrucoes .button {
    height: 80px;
  }

  .instrucoes img {
    top: calc(50vh - 500px);
  }

  .instrucoes p {
    margin-top: 30px;
  }
}

@media screen and (min-height: 1000px) and (min-width: 751px) {
  /* iPad */
  .instrucoes {
    font-size: 28px;
    max-height: 1100px;
  }
}

@media screen and (min-width: 800px) {
  .instrucoes img {
    left: calc(50vw - 400px);
  }
}
