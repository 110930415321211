@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

.resultados-container {
    font-family: 'Lato', sans-serif;
    min-height: 100%;
    font-size: 18px;
    user-select: none;
}

.resultados {
    display: grid;
    grid-template-rows: 5em auto;
    grid-gap: 10px;
    min-height: 100%;
    margin: 0 auto;
    max-width: 1040px;
    padding: 30px 20px 80px;
}

.resultados .cabecalho h1 {
    font-size: 2em;
    text-align: center;
    line-height: 1em;
}

.resultados .cabecalho p {
    font-size: 1.3em;
    text-align: center;
    color: #787878;
    margin-top: 10px;
    margin: 0 auto;
}

.resultados .conteudo-principal {
    max-width: 990px; /* parent-width - parent-padding - grid-gp */
    align-self: center;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto auto;
    grid-gap: 10px;
}

.resultados .resultado {
    width: 100%;
    min-width: 260px;
    position: relative;
}

.resultados .acertos {
    text-align: left;
    width: 100%;
}

.resultados .acertos-fundo {
    display: inline-block;
    background: linear-gradient(to bottom, #bdbbbb, #e7e7e7);
    width: calc(100vw - 6ch);
    max-width: calc(100% - 7ch);
    height: 8px;
}

.resultados .acertos-barra {
    background: linear-gradient(to top, rgb(32, 168, 89), rgb(53, 221, 123));
    width: 0%;
    height: 100%;
    vertical-align: middle;
}

.resultados .resultado[data-precision="low"] .acertos-barra {
    background: linear-gradient(to top, rgb(192, 71, 0), rgb(255, 95, 2));
}

.resultados .acertos-percentual {
    display: inline-block;
    color: #000;
    margin-left: 1ch;
}

.resultados iframe {
    width: 100%;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
}

.resultados .percentual {
    font-family: 'Lato';
    font-size: 60px;
    color: #219653;
}

.resultados .resultado[data-precision="low"] .percentual {
    color: rgb(235, 88, 3);
}

.resultados .tipo {
    font-family: 'Lato';
    font-size: 36px;
    color: #000;
    font-weight: 700;
    text-transform: capitalize;
}

.resultados .icon {
    height: 110px;
    position: absolute;
    top: 0;
    right: 0;
}

.resultados .descricao {
    font-size: 1.1em;
    line-height: 1.5em;
    color: #333333;
}

.resultados .descricao, .resultados .community-description {
    font-size: 1em;
    line-height: 1.5em;
    color: #333333;
}

.resultados .descricao strong, .resultados .descricao b {
    font-weight: 900;
    color: #000;
}

.resultados-container .controle-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-top: 10px;
    height: 80px;
    background: rgba(255, 255, 255, 0.75);
}

.resultados-container .controle {
    display: flex;
    width: 100%;
    max-width: 400px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4em;
    color: #828282;
    z-index: 1;
}

.resultados-container .controle .previous-button, .resultados-container .controle .next-button {
    height: 40px;
    width: 40px;
    cursor: pointer;
    transition-duration: 0.25s;
}

.resultados-container .controle img[data-status="disabled"] {
    opacity: 0.5;
    pointer-events: none;
}

.resultados-container .controle .previous-button {
    transform: rotateZ(180deg);
}

.resultados.veja-mais {
    display: flex;
    flex-direction: column;
}

.veja-mais .telegram-button {
    height: 55px;
    width: 100%;
    border-radius: 3px;
    background-color: #29A4E0;
    padding: 0.25rem;
    border: none;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #fff;
    max-width: 400px;
    margin: 15px auto 0;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
.veja-mais .telegram-button:hover {
    background-color: #1B96D2;    
}

.veja-mais .telegram-button .telegram-logo {
    height: 24px;
    margin-left: 0.5rem;
}

.veja-mais {
    grid-template-rows: 320px auto;
}

.veja-mais .cabecalho h1 {
    font-size: 2em;
    line-height: 1.2em;
}

.veja-mais .cabecalho p {
    font-size: 1.5em;
    max-width: 400px;
    margin: 10px auto 0;
}

.veja-mais .button-wrapper a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    margin: 15px auto 0;
    width: 100%;
    max-width: 600px;
    min-width: 250px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
}

.veja-mais .button-wrapper a:hover {
    background: rgb(30, 141, 76);
}

.veja-mais .community-cards-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 32px;
    column-gap: 20px;
    width: fit-content;
    margin: 32px auto;
}

.veja-mais .community-card {
    text-decoration: none;
    width: 180px;
    height: 140px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    transition-duration: 0.25s;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    }
}

.veja-mais .community-card .community-type-container {
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.veja-mais .community-card > img {
    height: 50px;
}

.veja-mais .community-card .community-type-container span {
    font-size: 20px;
    font-weight: 400;
    color: #222;
    text-transform: capitalize;
}

.veja-mais .community-card .whatsapp-container {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #075E54;
}

.veja-mais .community-card .whatsapp-container span {
    font-size: 16px;
    font-weight: 400;
    color: #075E54;
}

@media screen and (max-width: 850px) {
    .resultados {
        font-size: 16px;
        row-gap: 10px;
        padding: 20px 20px 80px;
    }

    .resultados .conteudo-principal {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        row-gap: 10px;
    }

    .resultados .resultado {
        grid-row: 1 / 2;
        width: 100%;
        height: 100%;
    }
    
    .resultados iframe {
        height: 100%;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    
    .resultado .descricao {
        grid-row: 3 / 4;
        width: 100%;
        height: 100%;
    }

    .resultados .percentual {
        font-size: 40px;
    }
    
    .resultados .tipo {
        font-size: 24px;
    }
    
    .resultados .icon {
        height: 70px;
    }
}

@media screen and (max-width: 600px) {
    .resultados {
        padding: 20px 10px 80px;
    }

    .veja-mais .community-cards-grid {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 12px;
        margin: 16px auto;
    }

    .veja-mais .community-card {
        width: 100%;
        height: 90px;
        padding: 16px 32px;

        flex-direction: row;
    }

    .veja-mais .community-card .community-type-container {
        height: 100%;
    }

    .veja-mais .community-card .community-type-container > span {
        font-size: 24px;
    }
}

@media screen and (min-height: 751px) and (min-width: 751px) {
    .veja-mais {
        grid-template-rows: 420px auto;
    }

    .resultados {
        font-size: 22px;
        max-width: 1200px;
        padding-bottom: 100px;
    }

    .resultados .conteudo-principal {
        max-width: 1150px; /*parent-width - parent-padding - grid-gap */
    }

    .resultados-container .controle-container {
        height: 100px;
    }

    .veja-mais .button-wrapper a, .veja-mais .telegram-button {
        height: 80px;
        max-width: 600px;
    }

    .veja-mais .telegram-button .telegram-logo {
        height: 36px;
        margin-left: 1.5rem;
    }

    .veja-mais .links, .veja-mais .cabecalho p, .resultados-container .controle {
        max-width: 600px;
    }

    .resultados-container .controle {
        max-width: 600px;
    }

    .resultados-container .controle .previous-button, .resultados-container .controle .next-button {
        height: 60px;
        width: 60px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .resultados-container {
        font-size: 28px;
        max-height: 1100px;
    }

    .resultados .resultado {
        grid-row: 1 / 2;
    }

    .resultados .conteudo-principal {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        row-gap: 10px;
    }
    
    .resultados iframe {
        width: 100%;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    
    .resultado .descricao {
        grid-row: 3 / 4;
    }
}

