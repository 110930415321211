@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

.apresentacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 100%;
    max-height: 750px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

.apresentacao .conteudo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.apresentacao img, .apresentacao .button {
    width: 90vw;
    max-width: 400px;
}

.apresentacao img {
    margin-top: 30px;
}

.apresentacao svg {
    transform: scale(1.4, 1.4);
    margin: 40px 0;
}

.apresentacao a {
    text-decoration: none;
}

.apresentacao h1 {
    font-size: 2.65em;
    font-weight: 900;
    margin-bottom: 2vh;
}

.apresentacao h2 {
    font-size: 2.15em;
    font-weight: 700;
    margin-top: 3vh;
}

.apresentacao p {
    font-size: 1.5em;
    text-align: center;
    max-width: 90vw;
}

.apresentacao strong {
    color: #000;
}

.apresentacao h1, .apresentacao h2 {
    line-height: 1.0em;
    text-align: center;
}

.apresentacao .subtitulo {
    color: #666;
}

.apresentacao .button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    margin: 15px 0 50px;
}

.apresentacao .button:hover {
    background: rgb(30, 141, 76);
}

@media screen and (max-height: 600px) {
    .apresentacao svg {
        transform: scale(1.2, 1.2);
        margin: 30px 0;
    }

    .apresentacao .button {
        margin-bottom: 20px;
    }
}

@media screen and (min-height: 751px) and (min-width: 751px) { /* iPad Pro */
    .apresentacao {
        font-size: 22px;
    }
    
    .apresentacao .button {
        height: 80px;
    }

    .apresentacao img, .apresentacao .button {
        max-width: 600px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .apresentacao {
        font-size: 28px;
        max-height: 1100px;
    }

    .apresentacao svg {
        transform: scale(1.6, 1.6);
        margin: 40px 0;
    }
}