@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

.icones-utilizados {
    min-height: 100%;
    margin: 0 auto;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    user-select: none;
    padding: 30px 0 50px;
}

.icones-utilizados h1 {
    font-size: 2.0em;
    text-align: center;
    margin-bottom: 20px;
}

.icones-utilizados .icons-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.icones-utilizados .icons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 110px;
    width: 315px;
    padding: 5px;
    margin: 15px auto;
}

.icones-utilizados .data-container {
    height: 100%;
    min-width: 190px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
}

.icones-utilizados .icons-container h2 {
    font-size: 1.2em;
    color: #219653;
}

.icones-utilizados .icons-container p {
    font-size: 0.9em;
}

.icones-utilizados .icons-container a {
    color: #1075AD;
}

.icones-utilizados .icons-container img {
    height: 90px;
    max-width: 90px;
}

.icones-utilizados .icons-container .free-license {
    color: #666;
    font-weight: 700;
}

.icones-utilizados #leader {
    margin-left: 15px;
}