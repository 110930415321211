@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

.avaliacao {
    display: grid;
    grid-template-rows: auto auto 55px;
    row-gap: 20px;
    margin: 0 auto;
    max-width: 800px;
    min-height: 100%;
    max-height: 750px;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    user-select: none;
    padding: 30px 20px 50px;
}

.avaliacao h1 {
    font-size: 1.9em;
    text-align: center;
    font-weight: 700;
}

.avaliacao p {
    font-size: 1.3em;
    text-align: center;
    color: #787878;
    margin: 10px auto 0;
    width: 90vw;
    max-width: 400px;
}

.avaliacao .form {
    height: 100%;
    width: 90vw;
    max-width: 400px;
    margin: 0 auto;
}

.avaliacao .fields {
    height: 2.33em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
}

.avaliacao .fields input {
    font-size: 1em;
    height: 1.8em;
    border: 1px solid #000;
    width: calc(100% - 45px);
    padding: 0 10px;
    border-radius: 2px;
}

.avaliacao .fields input#city {
    width: calc(100% - 45px - 5.5ch - 14px);
}

.avaliacao .fields select {
    font-size: 1em;
    width: 5.5ch;
    height: 1.8em;
    border-radius: 2px;
    border: 1px solid black;
}

.avaliacao .fields input::placeholder {
    color: #BDBDBD;
}

.avaliacao [data-status="error"] .fields input, .avaliacao [data-status="error"] .fields select {
    border-color: #E50D0D;
}

.avaliacao [data-status="valid"] .fields input, .avaliacao [data-status="valid"] .fields select {
    border-color: #219653;
}

.avaliacao .fields svg {
    display: inline-block;
    stroke-width: 2px;
}

.avaliacao [data-status="error"] .fields svg {
    fill: #E50D0D;
}

.avaliacao [data-status="valid"] .fields svg {
    fill: #219653;
}

.avaliacao #name-icon {
    width: 46px;
    height: 42px;
}

.avaliacao #whatsapp-icon {
    width: 30px;
    height: 30px;
    margin-right: 6px;
}

.avaliacao #home-icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.avaliacao #at-symbol {
    font-size: 30px;
    font-weight: 700;
    margin-right: 10px;
    padding-bottom: 0.2em;
}

.avaliacao [data-status="error"] #at-symbol {
    color: #E50D0D;
}

.avaliacao [data-status="valid"] #at-symbol {
    color: #219653;
}

.avaliacao .button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    margin: 0 auto;
    width: 90vw;
    max-width: 400px;
    cursor: pointer;
    user-select: none;
    border: none;
    text-decoration: none;
}

.avaliacao .button[data-enabled='false'] {
    pointer-events: none;
    opacity: 0.6;
}

.avaliacao .button:hover, .avaliacao .button:focus {
    background: rgb(30, 141, 76);
}

.avaliacao .politica {
    display: inline-block;
    color: #1075AD;
    text-decoration: none;
    border-bottom: 2px solid rgba(16, 117, 173, 0.5);
    padding-bottom: 1px;
}

.avaliacao .politica:focus {
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.avaliacao .mensagem-erro {
    font-size: 0.8em;
    color: #E50D0D;
    width: calc(100% - 45px);
    text-align: left;
    margin: 0;
    height: 2.5em;
    opacity: 0;
}

.avaliacao [data-status="error"] .mensagem-erro {
    opacity: 1;
}

@media screen and (min-height: 751px) and (min-width: 751px) { /* iPad Pro */
    .avaliacao {
        grid-template-rows: auto auto 80px;
    }
    .avaliacao {
        font-size: 22px;
    }
    
    .avaliacao .button {
        height: 80px;
        max-width: 600px;
    }

    .avaliacao .form, .avaliacao .fields {
        max-width: 600px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .avaliacao {
        font-size: 28px;
        max-height: 1100px;
    }
}