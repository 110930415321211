@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');

.perguntas {
    display: grid;
    grid-template-rows: 25px auto 55px;
    row-gap: 20px;
    margin: 0 auto;
    max-width: 800px;
    min-height: 100%;
    max-height: 750px;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    user-select: none;
    padding: 30px 20px 50px;
}

.perguntas .progresso {
    text-align: left;
    max-width: 800px;
}

.perguntas .progresso-fundo {
    display: inline-block;
    background: linear-gradient(to bottom, #bdbbbb, #e7e7e7);
    width: calc(100% - 6ch);
    max-width: calc(800px - 6ch);
    height: 8px;
    vertical-align: middle;
}

.perguntas .progresso-barra {
    background: linear-gradient(to top, rgb(32, 168, 89), rgb(53, 221, 123));
    width: 0%;
    height: 100%;
    vertical-align: middle;
}

.perguntas .progresso-percentual {
    display: inline-block;
    color: #777;
    font-size: 1.1em;
    margin-left: 1ch;
}

.perguntas .conteudo {
    height: 100%;
    max-width: 800px;
    text-align: center;
}

.perguntas .questao-numero {
    color: #777;
}

.perguntas .questao {
    text-align: justify;
    font-size: 1.1em;
    line-height: 1.5em;
}

.perguntas .pergunta-disc {
    display: inline-block;
    margin-left: 1.7ch;
    font-weight: 700;
}

.perguntas .escala {
    display: block;
    margin: 20px auto 0;
    stroke-width: 2px;
    cursor: pointer;
}

.perguntas .escala-numero {
    display: block;
    margin: -47px auto 0;
    cursor: pointer;
}

.perguntas .button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #27AE60;
    border-radius: 3px;
    font-size: 1.5em;
    height: 55px;
    cursor: pointer;
    margin: 0 auto;
    user-select: none;
    
    pointer-events: none;
    opacity: 0.6;
}

.perguntas .button[data-status="enabled"] {
    opacity: 1.0;
    pointer-events: all;
}

.perguntas .button:hover {
    background: rgb(30, 141, 76);
}

.perguntas img {
    width: 30px;
    opacity: 0.5;
    position: fixed;
    top: 10px;
    left: 5vw;

    display: none;
}

.perguntas .tudo-a-ver {
    color: #08B696;
    font-weight: 700;
    float: right;
}

.perguntas .nada-a-ver {
    color: #6C7C7B;
    font-weight: 700;
    float: left;
}

.perguntas .a-ver {
    width: 100%;
    max-width: 250px;
    margin: 5px auto 0;
    height: 10px;
    font-size: 1.2em;
}

@media screen and (min-width: calc(510px + 10vw)) {
    .perguntas .a-ver {
        max-width: calc(250px + 20ch);
        margin: -1.5em auto 0;
    }
}

@media screen and (max-height: 600px) {
    .perguntas {
        font-size: 16px;
        padding: 20px 10px;
    }
    
    .perguntas p {
        margin-top: 10px;
    }

    .perguntas .button {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 500px) {
    .perguntas .a-ver {
        margin-top: calc(-1em - 10px);
        max-width: calc(250px + 18ch);
    }
}

@media screen and (min-height: 751px) and (min-width: 751px) { /* iPad Pro */
    .perguntas {
        font-size: 22px;
        grid-template-rows: 35px auto 80px;
    }
    
    .perguntas .button {
        height: 80px;
    }

    .perguntas img {
        top: calc(50vh - 500px);
    }

    .perguntas .a-ver {
        margin-top: calc(-1.2em);
    }
}

@media screen and (min-height: 1000px) and (min-width: 751px) { /* iPad */
    .perguntas {
        font-size: 28px;
        max-height: 1100px;
    }
}

@media screen and (min-width: 800px) {
    .perguntas img {
        left: calc(50vw - 400px);
    }
}